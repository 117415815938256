import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colour from '@lushdigital/ui/colour';

const Background = styled.div`
  width: 100%;
  background-color: ${colour.darkgray};
`;

const Bar = styled.div`
  background-color: ${colour.white};
  height: 5px;
  transition: width 0.2s ease-in;
`;

const ProgressBar = ({ progress }) => (
  <Background>
    <Bar style={{ width: `${progress}%` }} />
  </Background>
);

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default ProgressBar;
