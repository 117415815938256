import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectVotingProvider } from '../../context/connectVoting';
import PageWrapper from '../../components/pageWrapper';
import Questionnaire from './questionnaire';
import routes from '../../routes';

const QuestionnaireWrapper = () => {
  const { t } = useTranslation();

  return (
    <ConnectVotingProvider>
      <PageWrapper title={t('CV_QUESTIONNAIRE')} route={routes.HOME.path}>
        <Questionnaire />
      </PageWrapper>
    </ConnectVotingProvider>
  );
};

export default QuestionnaireWrapper;
