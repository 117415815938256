import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from '@lushdigital/ui';
import Icon from '@lushdigital/icons';
import AlertsContext from '@lushdigital/ui/alerts/context';
import get from 'lodash/get';
import { useQuery } from 'react-apollo';
import { GET_CREATION } from './viewCreations.query';
import routes from '../../routes';
import CVLoader from '../../components/cvLoader';
import CVError from '../../components/cvError';
import Creation from '../../components/creation';
import useAssetRenderer from '../../hooks/useAssetRenderer';
import { strToURL } from '../../helpers';
import { Title, Text } from '../../helpers/helpers.styles';
import {
  ProgressBar,
  CreationContainer,
  IconContainer,
  CanvasIcon,
  DownloadButtons,
  ShareContainer,
} from './viewCreation.styles';

const ViewCreationContent = ({ creationId }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { insertAlert } = React.useContext(AlertsContext);
  const progressBar = React.useRef();

  const [isInProgress, setIsInProgress] = React.useState(false);
  const [isFullScreen, setIsFullScreen] = React.useState(false);
  const [creationName, setCreationName] = React.useState('creation');

  const {
    canvasRef,
    getImageSrc,
    getVideoSrc,
    saveFile,
    stopRenderingCanvas,
    resizeCanvas,
    setCreation,
    stopVideoDownload,
  } = useAssetRenderer();

  const { loading, error, data } = useQuery(GET_CREATION, {
    variables: { creation_id: creationId },
  });
  const creation = get(data, 'get_creation', false);

  const downloadVideo = async () => {
    setIsInProgress(true);
    getVideoSrc(
      (progress) => {
        if (progressBar.current !== null) {
          progressBar.current.style.width = `${progress}%`;
        }
      },
      () => {
        insertAlert({ type: 'error', message: t('CV_VIDEO_ERROR'), time: 5 });
      },
      (blob) => {
        saveFile(blob, `${creationName}-lush.mp4`);
        setIsInProgress(false);
        if (progressBar.current !== null) {
          progressBar.current.style.width = 0;
        }
      }
    );
  };

  const downloadImage = () => {
    saveFile(getImageSrc({ width: 2048, height: 2048 }), `${creationName}-lush.png`);
  };

  const handleFullScreen = () => {
    stopRenderingCanvas();
    setIsFullScreen(!isFullScreen);
  };

  const onFinishButtonClicked = () => {
    if (isInProgress) {
      stopVideoDownload();
    }
    history.push(routes.HOME.path.replace(':language', i18n.language));
  };

  React.useEffect(() => {
    if (creation) {
      setCreation(
        {
          frequency: creation.frequency,
          time_of_day: creation.time_of_day,
          colors: creation.colors,
          fragrances: creation.fragrances,
          ...(creation.ingredients !== [] && { ingredients: creation.ingredients }),
          ...(creation.extras !== [] && { extras: creation.extras }),
        },
        creation.creation_name
      );
      setCreationName(strToURL(creation.creation_name));
    }
  }, [creation, setCreation]);

  React.useEffect(() => {
    if (canvasRef.current !== null) {
      resizeCanvas();
    }
  }, [isFullScreen, canvasRef, resizeCanvas]);

  React.useEffect(() => {
    history.listen(() => {
      if (isInProgress) {
        stopRenderingCanvas();
        stopVideoDownload();
      }
    });
  });

  if (loading) {
    return <CVLoader />;
  }

  if (error) {
    return <CVError name="GET_USER_CREATIONS" error={error} />;
  }

  if (!loading && creation === null) {
    return (
      <>
        <Title>{t('CV_ERROR_TITLE')}</Title>
        <Text>{t('CV_ERROR_TEXT')}</Text>
      </>
    );
  }

  return (
    <>
      <CreationContainer className={isFullScreen ? 'fullscreen' : ''}>
        <ProgressBar ref={progressBar} />
        <IconContainer onClick={!isInProgress ? handleFullScreen : null}>
          <CanvasIcon icon={isFullScreen ? 'close' : 'fullscreen'} fill="#fff" />
        </IconContainer>
        <Creation creationId={creationId} size={{ height: '100%', width: '100%' }} assetCanvasRef={canvasRef} />
      </CreationContainer>
      <DownloadButtons>
        <Button disabled={isInProgress} theme="inverse-floating" onClick={downloadImage}>
          <Icon icon="image" fill="#fff" styles={{ marginRight: '1.2em', lineHeight: '0' }} />
          {t('CV_DOWNLOAD_IMAGE')}
        </Button>
        <Button disabled={isInProgress} theme="inverse-floating" onClick={downloadVideo}>
          <Icon icon="play-circle" fill="#fff" size="22" styles={{ marginRight: '1.2em', lineHeight: '0' }} />
          {t('CV_DOWNLOAD_VIDEO')}
        </Button>
      </DownloadButtons>
      <Button onClick={onFinishButtonClicked} margin="50px 0 0" fullWidth theme="inverse-clear">
        {t('CV_FINISH')}
      </Button>
      <ShareContainer>
        <Text margin="0 0 30px">{t('CV_SHARE')}</Text>
        <Icon icon="lush-labs" fill="#fff" size="20em" styles={{ marginTop: '-10.5em' }} />
      </ShareContainer>
    </>
  );
};

ViewCreationContent.propTypes = {
  creationId: PropTypes.string.isRequired,
};

export default ViewCreationContent;
