import React, { useEffect, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { createGlobalStyle } from 'styled-components';
import { ApolloProvider } from 'react-apollo';
import Cookies from 'universal-cookie';
import { I18nextProvider, Translation } from 'react-i18next';

import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { GlobalStyles, AlertProvider, AlertConsumer } from '@lushdigital/ui';
import { AuthProvider, getTokensClient } from '@lushdigital/auth';
import Icon from '@lushdigital/icons';
import Loader from '@lushdigital/ui/lushLoader';

// Config, setup & helpers
import env from './config/config.env';
import client from './apollo/apollo.client';
import { handleLanguageRedirect } from './i18n/i18n.helpers';
import i18n from './i18n/i18n.create';

// Routes
import renderRoutes from './routes/routes.render';
import configRoutes from './routes/routes.config';

//
const history = createBrowserHistory();

// local styles for project
const ProjectStyles = createGlobalStyle`
  body {
    background: #F6F7FB;
  }
`;

// Handle language redirect on route change
history.listen((location) => {
  handleLanguageRedirect({
    i18n,
    location,
    replace: history.replace,
  });
});

const App = () => {
  // Handle language redirect on app mount
  useEffect(() => {
    handleLanguageRedirect({
      i18n,
      location: history.location,
      replace: history.replace,
    });
  }, []);

  const tokens = getTokensClient(new Cookies());
  return (
    <>
      <GlobalStyles />
      <ProjectStyles />
      <ApolloProvider client={client(env.graphqlUrl)}>
        <Suspense fallback={<Loader position="absolute" />}>
          <I18nextProvider i18n={i18n}>
            <AuthProvider tokens={tokens}>
              <Router history={history}>
                <AlertProvider>
                  <AlertConsumer closeIcon={<Icon icon="close" fill="#fff" size="10px" />} />
                  <Translation>{(_, { i18n: { language } }) => renderRoutes(configRoutes, { language })}</Translation>
                </AlertProvider>
              </Router>
            </AuthProvider>
          </I18nextProvider>
        </Suspense>
      </ApolloProvider>
    </>
  );
};

// Render the app!
ReactDOM.render(<App />, document.getElementById('root'));
