import React from 'react';
import PropTypes from 'prop-types';
import { hasIn } from 'lodash';
import withAlerts from '@lushdigital/ui/alerts/withAlerts';
import Alert from '@lushdigital/ui/alerts/alert';
import { logApolloException } from '../sentry/sentry.setup';

export const deserialiseError = (error = {}) => {
  let code = 400;
  let message = 'Something went wrong!';
  if (error.graphQLErrors) {
    // Find custom errors if they exist
    const customError = error.graphQLErrors[0];
    if (hasIn(customError, 'extensions.status')) code = customError.extensions.status;
    if (hasIn(customError, 'extensions.message')) {
      const { message: serverMessage } = customError.extensions;
      // check if we have a string or object for messages
      if (typeof serverMessage === 'string') {
        message = serverMessage;
      } else if (typeof serverMessage === 'object') {
        message = message.message; // eslint-disable-line
        code = message.name === 'ValidationError' ? 422 : 400;
      }
    }
  }
  return {
    code,
    message,
  };
};

class Error extends React.Component {
  constructor(props) {
    super(props);
    const {
      error,
      addAlert,
      insertAlert,
      debug,
      alertTime,
      alertDismissible,
      report401,
      report404,
      report422,
      name,
      insertAlertProps,
    } = props;

    const { code, message } = deserialiseError(error);

    // Debugging
    if (debug) {
      // eslint-disable-next-line
      console.log({ error: JSON.stringify(error), code, message });
    }
    if (error) {
      logApolloException({
        error,
        code,
        name,
        allow401: report401,
        allow404: report404,
        allow422: report422,
      });
    }

    // Add an alert
    if (addAlert) {
      const addAlertProps = {
        type: 'error',
        message,
        time: alertTime,
        dismissible: alertDismissible,
        ...insertAlertProps,
        render: (values) => <Alert key={values.i} {...values} />,
        ...insertAlertProps,
      };

      insertAlert(addAlertProps);
    }

    this.state = {
      code,
      message,
    };
  }

  render() {
    const { children } = this.props;
    if (typeof children === 'function') {
      return children(this.state);
    }
    return children;
  }
}

Error.defaultProps = {
  children: null,
  debug: false,
  report401: false,
  report404: false,
  report422: false,
  logSentry: true,
  addAlert: true,
  alertTime: 20,
  alertDismissible: true,
  insertAlertProps: {},
  name: 'APOLLO_ERROR',
};

Error.propTypes = {
  insertAlert: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
  name: PropTypes.string,
  children: PropTypes.any,
  debug: PropTypes.bool,
  report401: PropTypes.bool,
  report404: PropTypes.bool,
  report422: PropTypes.bool,
  logSentry: PropTypes.bool,
  addAlert: PropTypes.bool,
  alertTime: PropTypes.number,
  insertAlertProps: PropTypes.object,
  alertDismissible: PropTypes.bool,
};

export default withAlerts(Error);
