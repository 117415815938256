import * as React from 'react';

import LushCollabsAsset from '@lushdigital/lush-collabs-asset';

const useAssetRenderer = () => {
  const canvasRef = React.useRef(null);
  const [loaded] = React.useState(true);
  const [renderer, setRenderer] = React.useState(undefined);
  const [isCanvasReturned, setIsCanvasReturned] = React.useState(false);
  const [votesApplied, setVotesApplied] = React.useState(false);

  // React.useEffect(() => {
  //   import('@lushdigital/lush-collabs-asset').then((lushCollabsAsset) => {
  //     LushCollabsAsset = lushCollabsAsset.default;
  //     setloaded(true);
  //   });
  // }, []);

  React.useEffect(() => {
    // Check if it exists. If it does, stop, cancelcapture and set renderer to null
    if (LushCollabsAsset && renderer === undefined && canvasRef.current) {
      setRenderer(new LushCollabsAsset(canvasRef.current, '/assets'));
    }
    return () => {
      if (renderer && loaded && canvasRef.current) {
        const gl = canvasRef.current.getContext('webgl2'); // eslint-disable-line
        if (gl) {
          gl.getExtension('WEBGL_lose_context').loseContext();
        }
        renderer.canvas.remove();
        renderer.stopRendering();
        renderer.cancelCaptureVideo();
        setRenderer(undefined);
      }
    };
  }, [loaded, canvasRef.current, renderer]); // eslint-disable-line

  const setCreation = (votes, creationName) => {
    if (!votesApplied) {
      setIsCanvasReturned(true);

      if (renderer) {
        renderer.applyVoteOptions(votes);
        renderer.setCreationName(creationName);
        setVotesApplied(true);
      }
    }
  };

  const stopRenderingCanvas = React.useCallback(() => renderer.stopRendering(), [renderer]);

  const startRendering = React.useCallback(() => renderer.startRendering(), [renderer]);

  const resizeCanvas = React.useCallback(() => {
    if (renderer) {
      renderer.resizeCanvas();
      renderer.startRendering();
    }
  }, [renderer]);

  const getImageSrc = React.useCallback(
    (size) => {
      if (isCanvasReturned) {
        return renderer.captureImageAsDataURL({
          width: size.width,
          height: size.height,
          bloom: false,
        });
      }
      return null;
    },
    [renderer, isCanvasReturned]
  );

  const getVideoSrc = React.useCallback(
    (onProgress, onFailure, onComplete) => renderer.captureVideo(onProgress, onFailure, onComplete),
    [renderer]
  );

  const saveFile = React.useCallback((blob, filename) => {
    LushCollabsAsset.FileSaver.saveAs(blob, filename);
  }, []);

  const stopVideoDownload = React.useCallback(() => {
    if (renderer !== undefined) {
      return renderer.cancelCaptureVideo();
    }
    return null;
  }, [renderer]);

  return {
    canvasRef,
    getImageSrc,
    getVideoSrc,
    saveFile,
    stopRenderingCanvas,
    startRendering,
    resizeCanvas,
    setCreation,
    stopVideoDownload,
  };
};

export default useAssetRenderer;
