import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import HomeContent from './homeContent';
import routes from '../../routes';
import { CVContainer, CVContent } from '../../helpers/helpers.styles';
import PageWrapper from '../../components/pageWrapper';

const HomeContainer = styled(CVContainer)`
  padding: 50px 0;
`;

const Home = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper title={t('CV_HOME')} route={routes.HOME.path}>
      <HomeContainer>
        <CVContent>
          <HomeContent />
        </CVContent>
      </HomeContainer>
    </PageWrapper>
  );
};

export default Home;
