import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Icon from '@lushdigital/icons';
import fluidProp from '@lushdigital/ui/fluidProp';
import UIContainer from '@lushdigital/ui/container';
import { RemoveScroll } from 'react-remove-scroll';
import useRouter from '../../hooks/useRouter';

// helpers
import routes from '../../routes';

// styles
const Bar = styled.div`
  background: #000;
  min-height: 80px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;

const Container = styled(UIContainer)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

const Link = styled(NavLink)`
  display: block;
  line-height: 0;
  margin: 18px 30px 18px 10px;
  position: relative;
  z-index: 5;

  ${fluidProp({
    prop: 'font-size',
    min: { value: 9 },
    max: { value: 11 },
  })}
`;

// exported component
const Header = () => {
  const { i18n } = useTranslation();
  const [menu] = React.useState(false);
  const router = useRouter();

  const hide = router.pathname.match(/(\/login)/);

  if (hide) return null;

  return (
    <RemoveScroll enabled={menu} forwardProps>
      <Bar className="main-navigation">
        <Container>
          <Link to={routes.HOME.path.replace(':language', i18n.language)}>
            <Icon icon="lush" xSize="8em" ySize="2.5em" fill="#fff" />
          </Link>
        </Container>
      </Bar>
    </RemoveScroll>
  );
};

export default Header;
