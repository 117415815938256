import styled from 'styled-components';
import Icon from '@lushdigital/icons';
import { CVContent } from '../../helpers/helpers.styles';

export const ProgressBar = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  display: block;
  width: 0;
  height: 100%;
  background: linear-gradient(90deg, rgba(50, 5, 75, 0.75) 0%, rgba(40, 30, 80, 0.75) 100%);
  z-index: 100;
`;

export const CreationContainer = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;

  &.fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(100vh - 70px);
    z-index: 100;

    ${ProgressBar} {
      display: none;
    }
  }

  @media (min-width: 620px) {
    height: 600px;

    &.fullscreen {
      height: calc(100vh - 80px);
    }
  }
`;

export const CreationContent = styled(CVContent)`
  position: static;
  padding: 50px 30px;

  @media (min-width: 767px) {
    padding: 70px 30px;
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 50;

  .fullscreen & {
    right: 20px;
    top: 20px;
  }

  @media (min-width: 767px) {
    right: 20px;
    top: 15px;

    .fullscreen & {
      right: 30px;
      top: 30px;
    }
  }
`;

export const CanvasIcon = styled(Icon)`
  &.fullscreen {
    width: 40px;
    height: 40px;

    @media (min-width: 767px) {
      width: 50px;
      height: 50px;
    }
  }
  &.close {
    width: 20px;
    height: 20px;

    @media (min-width: 767px) {
      width: 30px;
      height: 30px;
    }
  }
`;

export const DownloadButtons = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  button {
    margin-top: 20px;
  }

  @media (min-width: 767px) {
    flex-direction: row;

    button {
      margin-top: 0;
    }
  }
`;

export const ShareContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 7em;
`;
