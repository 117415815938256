export const MaxCreations = 3;

export const Steps = [
  {
    component: 'IconSelector',
    title: 'CV_BATHE_FREQUENCY',
    category: 'frequency',
    min: 1,
    max: 1,
    hasModal: false,
  },
  {
    component: 'IconSelector',
    title: 'CV_TIME_OF_DAY',
    category: 'time_of_day',
    min: 1,
    max: 1,
    hasModal: false,
  },
  {
    component: 'ColorSelector',
    title: 'CV_CHOOSE_COLOURS',
    category: 'colors',
    min: 1,
    max: 3,
    hasModal: false,
  },
  {
    component: 'ListSelector',
    title: 'CV_CHOOSE_FRAGRANCES',
    category: 'fragrances',
    min: 1,
    max: 2,
    hasModal: false,
  },
  {
    component: 'ListSelector',
    title: 'CV_SELECT_INGREDIENTS',
    description: 'CV_SELECT_INGREDIENTS_DESCRIPTION',
    category: 'ingredients',
    min: 0,
    max: 4,
    hasModal: true,
  },
  {
    component: 'ListSelector',
    title: 'CV_SELECT_EXTRAS',
    category: 'extras',
    min: 0,
    max: 3,
    hasModal: true,
  },
  {
    component: 'NameCreation',
    min: 1,
    max: 18,
  },
];
