import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Canvas = styled.canvas`
  display: block;
  width: 100%;
  height: 100%;
  touch-action: none;
  user-select: none;
  outline: none;
`;

const Creation = ({ size, assetCanvasRef }) => (
  <div style={size}>
    <Canvas ref={assetCanvasRef} />
  </div>
);

Creation.propTypes = {
  size: PropTypes.object.isRequired,
  assetCanvasRef: PropTypes.object.isRequired,
};

export default React.memo(Creation, (prev, next) => prev.creationId === next.creationId);
