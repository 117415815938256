import styled from 'styled-components';
import { H2, P } from '@lushdigital/ui';

export const CVContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('https://res.cloudinary.com/lush/image/upload/v1579189167/lush_com/site_assets/connect-voting/background.png');
  background-size: cover;
  background-position: center;
`;

export const CVContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  padding: 0 30px;
  z-index: 10;

  @media (min-width: 767px) {
    max-width: 790px;
  }
`;

export const Title = styled(H2)`
  color: #fff;
  font-size: 23px;
  line-height: 32px;
  text-align: center;

  @media (min-width: 767px) {
    font-size: 27px;
    line-height: 35px;
    margin-bottom: 30px;
  }
`;

export const Text = styled(P)`
  color: #fff;
  text-align: center;
  line-height: 22px;

  @media (min-width: 767px) {
    line-height: 25px;
    margin-bottom: 30px;
  }
`;

export const BtnContainer = styled.div`
  button {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
