import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import TokenValidation from './routes.tokenValidation';

// HOC
import withErrorPage from '../hoc/withErrorPage';
import withRouteBehaviour from '../hoc/withRouteBehaviour';

// Route Component
export default function renderRoutes(routesConfig, { language }) {
  return (
    <Switch>
      <Redirect exact from="/" to="/en" />
      {routesConfig.map((route) => {
        if (route.renderRedirect) {
          return (
            <Redirect
              exact={route.exact}
              key={route.path}
              path={route.path}
              to={route.redirect ? route.redirect : `/${language}/404`}
            />
          );
        }

        const component = withErrorPage(withRouteBehaviour(route.component));

        return (
          <TokenValidation
            exact={route.exact}
            key={route.path}
            path={route.path}
            title={route.title}
            component={component}
            authenticated={route.authenticated}
            notAuthenticated={route.notAuthenticated}
            permissions={route.permissions ? route.permissions : []}
            componentProps={{}}
            redirect={route.redirect ? route.redirect : `/${language}/401`}
          />
        );
      })}
    </Switch>
  );
}
