import styled, { keyframes } from 'styled-components';
import { CVContainer, CVContent } from '../../helpers/helpers.styles';

const backgroundAnim = keyframes`
  0% { background-position: 0% 0%; }
  33% { background-position: 100% 40%; }
  66% { background-position: 20% 70%; }
  100% { background-position: 0% 0%; }
`;

export const QuestionnaireContainer = styled(CVContainer)`
  flex-direction: column;
  justify-content: space-between;

  background-position: 0% 50%;
  background-size: 200% 200%;
  background-image: ${({ colors }) => `
    radial-gradient(
      circle at top left,
      ${colors[0] || '#f970f5'} 10%,
      ${colors[1] || '#2636e0'} 50%,
      ${colors[2] || '#3f1f79'} 90%
    );`}
  animation: ${backgroundAnim} 20s linear infinite;

  &.step-7 {
    justify-content: center;

    a,
    button {
      margin-top: 20px;
    }

    button[disabled] {
      border-color: rgba(255, 255, 255, 0.35);
      color: rgba(255, 255, 255, 0.35);
    }
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background: radial-gradient(
      rgba(0, 0, 0, ${(props) => props.opacity[0]}),
      rgba(0, 0, 0, ${(props) => props.opacity[1]})
    );
    z-index: 0;
  }
`;

export const StepContainer = styled(CVContent)`
  padding: 50px 30px;

  @media (min-width: 767px) {
    padding: 30px 30px 70px;
  }
`;

export const QuestionnaireBtnContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 40px 30px;
  background-color: black;
  z-index: 10;

  a,
  button {
    width: 100%;
    max-width: 730px;
    margin: 0 auto;
  }
`;
