import React from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import { P, Container, Modal, H3, CTA, CheckboxField, Row } from '@lushdigital/ui';
import { useTranslation } from 'react-i18next';

const ModalGlobalStyle = createGlobalStyle`
  .modal-wrapper {
    border-radius: 5px;
  }
`;

const FlexContainer = styled(Row)`
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);

  &:last-child {
    border-bottom-width: 0;
  }

  @media (min-width: 767px) {
    flex: 1 1 calc(50% - 10px);
    max-width: calc(50% - 10px);
    margin: 0 0 2em;
    padding: 2em;
    border: 1px solid rgba(255, 255, 255, 0.25);

    &:nth-child(odd) {
      margin-right: 2em;
    }

    &:last-child {
      border-bottom-width: 1px;
    }
  }

  span {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`;

const BoldP = styled(P)`
  font-weight: bold;
`;

const InfoBtn = styled(CTA)`
  padding: 10px;
  margin: 0 2em;
  background-color: rgba(255, 255, 255, 0.25);
  border: 0;
  color: #ffffff;
  font-size: 7px;
`;

const ModalContent = styled(Container)`
  text-align: center;
`;

const ModalDesc = styled.div`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: -2.5em;
    left: -3.4em;
    width: calc(100% + 6.8em);
    height: 1px;
    background-color: rgba(234, 234, 234, 1);
  }
`;

const ListItem = ({ item, increment, decrement, disabled, selectedOptions, hasModal }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState(false);
  const [modalVisible, setModalVisible] = React.useState(false);
  const toggleModal = () => setModalVisible(!modalVisible);

  React.useEffect(() => {
    selectedOptions.forEach((option) => {
      if (option === item.tag_name) {
        setSelected(true);
      }
    });
  }, [selectedOptions, item]);

  const updateSelected = () => {
    const currentState = !selected;
    setSelected(currentState);

    if (currentState) {
      increment(item);
    } else {
      decrement(item);
    }
  };

  return (
    <>
      <FlexContainer flexWrap="nowrap" alignItems="center" padding="2em 0">
        <BoldP color="#fff">{t(`${item.label}`)}</BoldP>
        {hasModal && (
          <InfoBtn onClick={toggleModal} circle iconOffset="0" theme="inverse" iconBefore="info" showContent={false}>
            {t('CV_MORE_INFORMATION')}
          </InfoBtn>
        )}
        <CheckboxField
          name={item.tag_name}
          type="checkbox"
          label={t(`${item.label}`)}
          theme="white"
          tick
          circle
          large
          margin="0"
          checked={item.required ? true : selected}
          disabled={item.required || (!selected && disabled)}
          onChange={updateSelected}
        />
      </FlexContainer>

      {modalVisible && (
        <Modal onClose={toggleModal} mobileFullscreen="0">
          <ModalGlobalStyle />
          <ModalContent margin="2em 0 0">
            {item.imageURL && <img src={item.imageURL} alt={t(`${item.label}`)} />}
            <H3 fontSize="23" align="center" margin="30px 0 40px">
              {t(`${item.label}`)}
            </H3>
            <ModalDesc>{item.description && <P margin="45px 0">{t(`${item.description}`)}</P>}</ModalDesc>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

ListItem.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string.isRequired,
    tag_name: PropTypes.string.isRequired,
    description: PropTypes.string,
    imageURL: PropTypes.string,
    required: PropTypes.bool,
  }).isRequired,
  increment: PropTypes.func.isRequired,
  decrement: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  hasModal: PropTypes.bool,
};

ListItem.defaultProps = {
  hasModal: false,
};

export default ListItem;
