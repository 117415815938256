import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-apollo';
import { Button } from '@lushdigital/ui';
import { useHistory } from 'react-router-dom';

import { useConnectVotingContext } from '../../context/connectVoting';
import routes from '../../routes';

import { CVContent, Title, Text } from '../../helpers/helpers.styles';
import { QuestionnaireContainer, StepContainer, QuestionnaireBtnContainer } from './questionnaire.styles';
import Progress from '../../components/progress';
import { ADD_CREATION } from './questionnaire.mutation';

import ColorSelector from '../../components/colorSelector';
import ListSelector from '../../components/list';
import IconSelector from '../../components/iconSelector';
import NameCreation from '../../components/nameCreation';

const components = {
  ColorSelector,
  ListSelector,
  IconSelector,
  NameCreation,
};

const Questionnaire = () => {
  const { t, i18n } = useTranslation();
  const scrollRef = React.createRef();
  const history = useHistory();
  const {
    continueDisabled,
    creationName,
    selectedColors,
    step,
    currStep,
    totalSteps,
    onContinueButtonClicked,
    votes,
    selectedOpacity,
  } = useConnectVotingContext();
  const StepScreen = components[currStep.component];

  const [addCreation] = useMutation(ADD_CREATION, {
    onCompleted: (mutationData) => {
      history.push(
        routes.CREATION.path.replace(':language', i18n.language).replace(':creation_id', mutationData.add_creation.id)
      );
    },
  });

  const postMutation = () => {
    addCreation({
      variables: {
        creator_id: 1,
        creator_name: `XXX XXX`,
        creation_name: creationName,
        frequency: votes.frequency,
        time_of_day: votes.time_of_day,
        colors: votes.colors,
        fragrances: votes.fragrances,
        ingredients: votes.ingredients ? votes.ingredients : [],
        extras: votes.extras ? votes.extras : [],
      },
    });
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  return (
    <QuestionnaireContainer
      colors={selectedColors.map((color) => color.hex)}
      opacity={selectedOpacity}
      ref={scrollRef}
      className={`step-${step}`}
    >
      {step < totalSteps && (
        <>
          <CVContent>
            <Progress />
            <Title>{t(currStep.title)}</Title>
            {currStep.description && <Text margin="30px 0 0">{t(currStep.description)}</Text>}
          </CVContent>
          <StepContainer>
            <StepScreen />
          </StepContainer>
          <QuestionnaireBtnContainer>
            <Button disabled={continueDisabled} fullWidth theme="inverse-clear" onClick={onContinueButtonClicked}>
              {t('CV_CONTINUE')}
            </Button>
          </QuestionnaireBtnContainer>
        </>
      )}

      {step === totalSteps && (
        <StepContainer>
          <StepScreen />
          {continueDisabled && (
            <Button disabled theme="inverse-clear" fullWidth>
              {t('CV_BUILD_CREATION')}
            </Button>
          )}
          {!continueDisabled && (
            <Button theme="inverse-clear" fullWidth onClick={postMutation}>
              {t('CV_BUILD_CREATION')}
            </Button>
          )}
        </StepContainer>
      )}
    </QuestionnaireContainer>
  );
};

export default Questionnaire;
