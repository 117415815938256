// base url
const BASE = '/:language';

// exported
const routes = {
  HOME: {
    path: BASE,
  },
  CREATE: {
    path: `${BASE}/create`,
  },
  CREATION: {
    path: `${BASE}/creation/:creation_id`,
  },
  RESULTS: {
    path: `${BASE}/results`,
  },
  AUTHENTICATED: {
    path: `${BASE}/authenticated`,
  },
};

export default routes;
