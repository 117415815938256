import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import get from 'lodash/get';
import { useQuery } from 'react-apollo';
import { GET_CATEGORIES_VOTES } from './results.query';

import { Text } from '../../helpers/helpers.styles';
import CVLoader from '../../components/cvLoader';
import CVError from '../../components/cvError';

const ResultText = styled(Text)`
  max-width: 400px;
  margin: 20px auto;
  text-align: left;
`;

const ResultsValues = ({ categoryName }) => {
  const [totalCount, setTotalCount] = React.useState(0);
  const [sortedList, setSortedList] = React.useState([]);

  const { loading, error, data } = useQuery(GET_CATEGORIES_VOTES, {
    variables: { category: categoryName },
  });
  const results = get(data, 'get_categories_votes.category', false);

  const votesCount = (items) => {
    const count = {};

    items.forEach((item) => {
      if (item in count) {
        count[item]++; // eslint-disable-line
      } else {
        count[item] = 1;
      }
    });

    return count;
  };

  React.useEffect(() => {
    if (results.length > 0) {
      const itemsValues = votesCount(results);
      const list = Object.keys(itemsValues).map((name) => {
        const value = itemsValues[name];
        return { name, value };
      });
      setSortedList(list.sort((a, b) => b.value - a.value));
      setTotalCount(results.length);
    }
  }, [results]);

  if (loading) {
    return <CVLoader />;
  }

  if (error) {
    return (
      <CVError
        name="GET_CATEGORIES_VOTES"
        error={error}
        customMessage="Sorry we couldn&#39;t load the results, try reloading the page"
      />
    );
  }

  return sortedList.map((item) => {
    const perc = Math.floor((item.value * 100) / totalCount);
    return (
      <ResultText key={item.name}>
        {item.value} {item.name} ({perc}%)
      </ResultText>
    );
  });
};

ResultsValues.propTypes = {
  categoryName: PropTypes.string.isRequired,
};

export default ResultsValues;
