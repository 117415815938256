import React from 'react';
import styled from 'styled-components';
import { colour, Row, P } from '@lushdigital/ui';
import Icon from '@lushdigital/icons';
import { useConnectVotingContext } from '../../context/connectVoting';
import ProgressBar from './progressBar';

const Container = styled.div`
  padding: 50px 0;
  min-height: 50px;
  overflow: hidden;
`;

const ActionRow = styled(Row)`
  position: relative;
`;

const BackIcon = styled.a`
  cursor: pointer;
  position: absolute;
  left: 0;
`;

const Progress = () => {
  const { step, totalSteps, goBack } = useConnectVotingContext();

  const getCurrStep = () => {
    if (step > totalSteps) {
      return totalSteps;
    }
    if (step <= 1) {
      return 1;
    }
    return step;
  };

  return (
    <Container>
      <ActionRow padding="0 0 30px 0">
        <BackIcon
          onClick={(e) => {
            e.preventDefault();
            goBack();
          }}
        >
          <Icon icon="long-arrow-left" fill={colour.white} size="20" />
        </BackIcon>
        <P fontSize="11px" align="center" color={colour.white}>
          {getCurrStep(step, totalSteps)} / {totalSteps}
        </P>
      </ActionRow>
      <Row>
        <ProgressBar progress={(getCurrStep(step, totalSteps) / totalSteps) * 100} />
      </Row>
    </Container>
  );
};

export default Progress;
