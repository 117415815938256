import React from 'react';
import styled from 'styled-components';
import Row from '@lushdigital/ui/row';
import { useConnectVotingContext } from '../../context/connectVoting';
import ListItem from './listItem';

import Details from '../../config/config.details.json';

const ItemRow = styled(Row)`
  align-items: stretch;
  padding: 0;
`;

const List = () => {
  const { handleMin, setVoteOptions, currStep, votes } = useConnectVotingContext();
  const preSelected = votes[currStep.category] || [];
  const [selectedOptions, setSelectedOptions] = React.useState(preSelected);
  const [currentCategory, setCurrentCategory] = React.useState(currStep.category);

  const incrementSelected = (clickedOption) => {
    const currentOptions = [...selectedOptions, clickedOption.tag_name];

    setSelectedOptions(currentOptions);
    setVoteOptions(currStep.category, currentOptions);
  };

  const decrementSelected = (clickedOption) => {
    if (selectedOptions.length > 0) {
      const currentOptions = selectedOptions.filter((item) => item !== clickedOption.tag_name);
      setSelectedOptions(currentOptions);
      setVoteOptions(currStep.category, currentOptions);
    }
  };

  React.useEffect(() => {
    // Check if currStep.category has changed.
    // If it hasn't, check if the minimum has been reached
    // If it has, change currentCategory and reset selected
    if (currStep.category === currentCategory) {
      handleMin(preSelected.length);
    } else {
      setCurrentCategory(currStep.category);
      setSelectedOptions(preSelected);
    }
  }, [currStep.category, currentCategory, handleMin]); // eslint-disable-line

  return (
    <ItemRow>
      {Object.values(Details[currStep.category]).map((uniqueItem) => (
        <ListItem
          key={uniqueItem.tag_name}
          item={uniqueItem}
          increment={incrementSelected}
          decrement={decrementSelected}
          disabled={preSelected.length >= currStep.max}
          selectedOptions={selectedOptions}
          hasModal={currStep.hasModal}
        />
      ))}
    </ItemRow>
  );
};

export default List;
