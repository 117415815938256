import React, { Component } from 'react';
import ErrorPage from '../../components/errorPage';

const withErrorPage = (WrappedComponent) => {
  class ErrorWrapper extends Component {
    constructor(props) {
      super(props);
      this.state = { error: null };
    }

    componentDidCatch(error) {
      this.setState({ error });
    }

    render() {
      const { error } = this.state;

      if (error) {
        return <ErrorPage error={error} />;
      }
      return <WrappedComponent {...this.props} />;
    }
  }

  return ErrorWrapper;
};

export default withErrorPage;
