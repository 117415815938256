import React from 'react';
import PropTypes from 'prop-types';
import Error from '../../apollo/apollo.error';
import { Title, Text } from '../../helpers/helpers.styles';

const CVLoader = ({ name, error, customMessage }) => (
  <>
    <Error name={name} addAlert={false} error={error} />
    <Title>Error</Title>
    <Text>{customMessage || error.message}</Text>
  </>
);

CVLoader.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.any.isRequired,
  customMessage: PropTypes.string,
};

CVLoader.defaultProps = {
  customMessage: '',
};

export default CVLoader;
