import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@lushdigital/ui/button';
import styled from 'styled-components';
import routes from '../../routes';
import { CVContainer, CVContent, BtnContainer } from '../../helpers/helpers.styles';
import ResultsValues from './resultsValues';
import PageWrapper from '../../components/pageWrapper';

const ResultBtnContainer = styled(BtnContainer)`
  display: flex;
  flex-direction: column;
`;

const Results = () => {
  const { t } = useTranslation();
  const [activeCategory, setActiveCategory] = React.useState('frequency');
  const categories = ['frequency', 'time_of_day', 'colors', 'fragrances', 'ingredients', 'extras'];

  const handleCat = (e) => {
    setActiveCategory(e.currentTarget.id);
  };

  return (
    <PageWrapper title={t('CV_VIEW_RESULTS')} route={routes.RESULTS.path}>
      <CVContainer>
        <ResultBtnContainer>
          {categories.map((category) => (
            <Button key={category} id={category} onClick={handleCat} disabled={activeCategory === category}>
              {category}
            </Button>
          ))}
        </ResultBtnContainer>
        <CVContent>
          <ResultsValues categoryName={activeCategory} />
        </CVContent>
      </CVContainer>
    </PageWrapper>
  );
};

export default Results;
