import React from 'react';
import styled from 'styled-components';
import Container from '@lushdigital/ui/container';
import { useConnectVotingContext } from '../../context/connectVoting';
import Color from './color';

import { colors } from '../../config/config.details.json';

const ColorContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 250px;
  margin: 0 auto;
`;

const ColorSelector = () => {
  const { handleMin, setVoteOptions, selectedColors, setSelectedColors, currStep } = useConnectVotingContext();

  const incrementSelected = (clickedColor) => {
    const currentColors = [...selectedColors, clickedColor];

    setSelectedColors(currentColors);
    setVoteOptions(currStep.category, currentColors);
  };

  const decrementSelected = (clickedColor) => {
    if (selectedColors.length > 0) {
      const currentColors = selectedColors.filter((color) => color !== clickedColor);
      setSelectedColors(currentColors);
      setVoteOptions(currStep.category, currentColors);
    }
  };

  React.useEffect(() => {
    handleMin(selectedColors.length);
  }, [selectedColors]); // eslint-disable-line

  return (
    <ColorContainer>
      {colors.map((color) => (
        <Color
          key={color.tag_name}
          color={color}
          increment={incrementSelected}
          decrement={decrementSelected}
          selected={!!selectedColors.find((option) => option.tag_name === color.tag_name)}
          disabled={selectedColors.length >= currStep.max}
        />
      ))}
    </ColorContainer>
  );
};

export default ColorSelector;
