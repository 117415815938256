import { getEnvironment } from '@lushdigital/helper-functions';

const production = {
  graphqlUrl: 'https://lush-gql.frontendserviceaccount.com/graphql',
};

const staging = {
  graphqlUrl: 'https://lush-gql-staging.frontendserviceaccount.com/graphql',
};

const development = {
  graphqlUrl: 'http://localhost:7000/graphql',
};

const getEnvConfig = () => {
  switch (getEnvironment()) {
    case 'production':
      return production;
    case 'staging':
      return staging;
    case 'development':
    default:
      return development;
  }
};

export default {
  ...getEnvConfig(),
};
