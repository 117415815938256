import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { func, string, object, bool } from 'prop-types';
import { get } from 'lodash';

// helpers
import { generateSocialImage } from '../../helpers';

// component
class PageHelmet extends React.PureComponent {
  render() {
    const {
      t,
      loading,
      page,
      type,
      url,
      noindex,
      twitterImage,
      facebookImage,
      title,
      hero,
      description,
      socialDescription,
      keywords,
    } = this.props;

    if (loading) {
      return (
        <>
          <Helmet>
            <title>{t('LOADING')}</title>
            {noindex && <meta name="robots" content="noindex" />}
          </Helmet>
        </>
      );
    }

    const pageTitle = get(page, title, '') || get(page, 'title', '');
    const pageDescription = get(page, description, '');
    const pageSocialDescription = get(page, socialDescription, '') || pageDescription;
    const pageKeywords = get(page, keywords, '');
    const pageHero = get(page, hero, '');

    return (
      <Helmet
        key={`${pageTitle}+${pageDescription}`}
        title={pageTitle}
        meta={[
          {
            name: 'description',
            content: pageDescription,
          },
          {
            name: 'keywords',
            content: pageKeywords,
          },
          {
            name: 'og:site_name',
            content: 'Lush Labs',
          },
          {
            name: 'twitter:site',
            content: '@LushLtd',
          },
          {
            name: 'twitter:card',
            content: 'summary_large_image',
          },
          {
            name: 'twitter:title',
            content: pageTitle,
          },
          {
            name: 'og:description',
            content: pageSocialDescription,
          },
          {
            name: 'og:title',
            content: pageTitle,
          },
          {
            name: 'og:type',
            content: type,
          },
          {
            name: 'og:url',
            content: url,
          },
          {
            name: 'og:image',
            content: facebookImage === '' ? generateSocialImage(pageHero, 1200, 628) : facebookImage,
          },
          {
            name: 'twitter:image',
            content: twitterImage === '' ? generateSocialImage(pageHero, 1200, 628) : twitterImage,
          },
          ...[noindex ? { name: 'robots', content: 'noindex' } : {}],
        ]}
      />
    );
  }
}

PageHelmet.defaultProps = {
  type: 'website',
  noindex: false,
  facebookImage: '',
  twitterImage: '',
  title: 'seo.title',
  keywords: 'seo.keywords',
  hero: 'hero',
  description: 'seo.description',
  socialDescription: 'social.description',
};

PageHelmet.propTypes = {
  t: func.isRequired,
  loading: bool.isRequired,
  page: object.isRequired,
  type: string,
  url: string.isRequired,
  twitterImage: string,
  facebookImage: string,
  noindex: bool,
  title: string,
  keywords: string,
  hero: string,
  description: string,
  socialDescription: string,
};

export default memo(withTranslation(['lush'])(PageHelmet));
