import React from 'react';
import { Context as AuthContext } from '@lushdigital/auth';

// component
const Logout = () => {
  const { logout } = React.useContext(AuthContext);

  React.useEffect(() => {
    async function signout() {
      try {
        await logout();
      } catch (e) {
        // console.log(e);
      }
      window.location.replace('/en');
    }
    signout();
  }, []); // eslint-disable-line

  return null;
};

export default Logout;
