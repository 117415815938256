import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Icon from '@lushdigital/icons';
import SrOnly from '@lushdigital/ui/srOnly';

const RadioBtnContainer = styled.div`
  text-align: center;
  cursor: pointer;
`;

const ItemIcon = styled(Icon)`
  opacity: 0.5;

  ${SrOnly}:checked ~ & {
    opacity: 1;
  }
`;

const LabelText = styled.span`
  display: block;
  font-weight: bold;
  color: #fff;
  font-size: 17px;
  margin-top: 10px;
  opacity: 0.5;

  ${SrOnly}:checked ~ & {
    opacity: 1;
  }
`;

const OptionIcon = ({ category, icon, handleOptionChange, selectedOptions }) => {
  const { t } = useTranslation();

  return (
    <label htmlFor={icon.tag_name}>
      <RadioBtnContainer>
        <SrOnly
          as="input"
          type="checkbox"
          id={icon.tag_name}
          name={category}
          checked={selectedOptions[0] === icon.tag_name}
          onChange={handleOptionChange}
        />
        <ItemIcon icon={icon.icon_name} fill="#FFF" size="50px" />
        <LabelText>{t(`${icon.label}`)}</LabelText>
      </RadioBtnContainer>
    </label>
  );
};

OptionIcon.propTypes = {
  category: PropTypes.string.isRequired,
  icon: PropTypes.shape({
    label: PropTypes.string.isRequired,
    tag_name: PropTypes.string.isRequired,
    icon_name: PropTypes.string.isRequired,
  }).isRequired,
  handleOptionChange: PropTypes.func.isRequired,
  selectedOptions: PropTypes.array,
};

OptionIcon.defaultProps = {
  selectedOptions: [],
};

export default OptionIcon;
