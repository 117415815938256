import config from '../config';

export const handleLanguageRedirect = ({ i18n, location, replace }) => {
  const paths = location.pathname.split('/');

  const languageIsValid = config.languages.includes(paths[1]);

  if (!languageIsValid) {
    paths[1] = 'en';
    replace(paths.join('/'));
  }

  i18n.changeLanguage(paths[1]);
};
