import React from 'react';
import { useTranslation } from 'react-i18next';
import Input from '@lushdigital/ui/input';
import styled from 'styled-components';
import { useConnectVotingContext } from '../../context/connectVoting';
import { Title } from '../../helpers/helpers.styles';
import useAssetRenderer from '../../hooks/useAssetRenderer';
import Creation from '../creation';

const NameTitle = styled(Title)`
  margin: 40px 0;
`;

const NameInput = styled(Input)`
  background-color: rgba(255, 255, 255, 0.2);
  border-color: transparent;
  color: #ffffff;
`;

const CreationContainer = styled.div`
  height: 300px;
`;

const NameCreation = () => {
  const { t } = useTranslation();
  const { canvasRef, setCreation } = useAssetRenderer();
  const { setCreationName, handleMin, votes } = useConnectVotingContext();

  const checkInputValue = (e) => {
    handleMin(e.currentTarget.value.length);
    setCreationName(e.currentTarget.value);
  };

  React.useEffect(() => {
    setCreation(votes);
  });

  return (
    <>
      <CreationContainer>
        <Creation creationId="new" size={{ width: '100%', height: '100%' }} assetCanvasRef={canvasRef} />
      </CreationContainer>
      <NameTitle>{t('CV_NAME_CREATION')}</NameTitle>
      <NameInput placeholder={t('CV_NAME_PLACEHOLDER')} type="text" fullWidth onChange={checkInputValue} />
    </>
  );
};

export default NameCreation;
