// page imports
import Home from '../pages/home';
import Questionnaire from '../pages/questionnaire';
import Creation from '../pages/viewCreation';
import Results from '../pages/results';

//
import Login from '../pages/login';
import Logout from '../pages/logout';

//
import NotFound from '../components/notFound';
import NotAuthorized from '../components/notAuthorized';

// config
import routes from './index';

// base url
const BASE = '/:language';

// exported
const routesConfig = [
  {
    exact: true,
    component: Home,
    authenticated: false,
    path: BASE,
    title: 'HOME',
  },
  {
    exact: true,
    component: Questionnaire,
    authenticated: false,
    path: routes.CREATE.path,
    title: 'CREATE',
  },
  {
    exact: true,
    component: Creation,
    authenticated: false,
    path: routes.CREATION.path,
    title: 'CREATION',
  },
  {
    exact: true,
    component: Results,
    authenticated: true,
    path: routes.RESULTS.path,
    title: 'RESULTS',
  },

  // Supporting routes
  {
    exact: true,
    component: Login,
    authenticated: false,
    notAuthenticated: true,
    path: `${BASE}/login`,
    title: 'LOGIN',
  },
  {
    exact: true,
    component: Logout,
    authenticated: false,
    path: `${BASE}/logout`,
    title: 'LOGOUT',
  },
  {
    component: NotAuthorized,
    authenticated: true,
    path: `${BASE}/401`,
    title: '401',
  },
  {
    // Must be last!
    component: NotFound,
    authenticated: false,
    path: `${BASE}/*`,
    title: '404',
  },
];

export default routesConfig;
