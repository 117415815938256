import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from '@lushdigital/ui';

import { Title, Text, BtnContainer } from '../../helpers/helpers.styles';

//
import routes from '../../routes';

//
const HomeContent = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  return (
    <>
      <Title margin="0 0 30px">{t('CV_INTRO_TITLE')}</Title>
      <Text margin="0 0 30px">{t('CV_INTRO_TEXT')}</Text>

      <BtnContainer>
        <Button
          onClick={() => {
            history.push(routes.CREATE.path.replace(':language', i18n.language));
          }}
          theme="inverse-clear"
          fullWidth
        >
          {t('CV_START_CREATING')}
        </Button>
      </BtnContainer>
    </>
  );
};

export default HomeContent;
