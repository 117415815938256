import React from 'react';
import styled from 'styled-components';
import Container from '@lushdigital/ui/container';
import { useConnectVotingContext } from '../../context/connectVoting';
import OptionIcon from './optionIcon';

import Details from '../../config/config.details.json';

const IconContainer = styled(Container)`
  display: flex;
  justify-content: center;
  padding: 0 20px;

  & > label {
    flex: 1 0 0;
    margin: 0 40px 0 0;

    &:last-child {
      margin-right: 0;
    }

    @media (min-width: 767px) {
      margin: 0 80px 0 0;
    }
  }
`;

const IconSelector = () => {
  const { handleMin, setVoteOptions, currStep, votes } = useConnectVotingContext();
  const [selectedOptions, setSelectedOptions] = React.useState(
    votes[currStep.category] ? votes[currStep.category] : []
  );

  React.useEffect(() => {
    if (votes[currStep.category]) {
      setSelectedOptions(votes[currStep.category]);
      handleMin(votes[currStep.category].length);
    }
  }, [handleMin]); // eslint-disable-line

  const handleOptionChange = (e) => {
    setSelectedOptions([e.currentTarget.id]);
    setVoteOptions(currStep.category, [e.currentTarget.id]);

    handleMin(selectedOptions.length);
  };

  return (
    <IconContainer padding="0 20px" margin="0 auto" maxWidth="375px">
      {Object.values(Details[currStep.category]).map((icon) => (
        <OptionIcon
          key={icon.tag_name}
          category={currStep.category}
          icon={icon}
          handleOptionChange={handleOptionChange}
          selectedOptions={selectedOptions}
        />
      ))}
    </IconContainer>
  );
};

export default IconSelector;
