import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Steps } from '../config/config.connectVoting';

export const connectVotingContext = React.createContext({});
connectVotingContext.displayName = 'connectVotingContext';

export const useConnectVotingContext = () => {
  const context = React.useContext(connectVotingContext);
  if (Object.keys(context).length === 0) {
    throw new Error('useConnectVotingContext must be used within a connectVotingContext');
  }
  return context;
};

export const ConnectVotingProvider = ({ children }) => {
  const history = useHistory();

  const [step, setStep] = React.useState(1);
  const [creationName, setCreationName] = React.useState('creation');
  const [continueDisabled, setContinueDisabled] = React.useState(true);
  const [selectedColors, setSelectedColors] = React.useState([]);
  const [votes, setVotes] = React.useState({});
  const [selectedOpacity, setSelectedOpacity] = React.useState([0.4, 0.5]);

  const totalSteps = Steps.length;
  const currStep = Steps[step - 1];

  const goBack = () => {
    if (step === 1) {
      history.goBack();
      return;
    }
    setStep(step - 1);
  };

  const handleMin = (selected) => {
    setContinueDisabled(selected < currStep.min);
  };

  const onContinueButtonClicked = () => {
    setContinueDisabled(true);
    setStep(step + 1);
  };

  const setVoteOptions = (category, options) => {
    if (category === 'colors') {
      setSelectedColors(options);

      setVotes((prevState) => ({
        ...prevState,
        colors: options.map((color) => color.tag_name),
      }));
    } else {
      if (category === 'time_of_day') {
        switch (options[0]) {
          case 'morning':
            setSelectedOpacity([0.5, 0.3]);
            break;
          case 'daytime':
            setSelectedOpacity([0.2, 0.5]);
            break;
          case 'evening':
            setSelectedOpacity([0.5, 0.7]);
            break;
          default:
            break;
        }
      }

      setVotes((prevState) => ({ ...prevState, [category]: options }));
    }
  };

  return (
    <connectVotingContext.Provider
      value={{
        creationName,
        setCreationName,
        step,
        continueDisabled,
        selectedColors,
        setSelectedColors,
        totalSteps,
        currStep,
        goBack,
        handleMin,
        onContinueButtonClicked,
        setVoteOptions,
        votes,
        selectedOpacity,
      }}
    >
      {children}
    </connectVotingContext.Provider>
  );
};

ConnectVotingProvider.displayName = 'ConnectVotingProvider';

ConnectVotingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
