import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';
import Cache from 'i18next-localstorage-cache';

import CONFIG from '../config';

i18n
  .use(XHR)
  .use(Cache)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
    },

    wait: true, // globally set to wait for loaded translations in withTranslation hoc

    ns: ['connect-voting', 'common'],
    defaultNS: 'connect-voting',
    fallbackNS: 'common',
    contextSeparator: '@',

    cache: {
      enabled: true,
    },

    load: 'all',

    lngWhitelist: CONFIG.languages,

    lowerCaseLng: true,

    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });

export default i18n;
