import gql from 'graphql-tag';

export const GET_CREATION = gql`
  query($creation_id: String!) {
    get_creation(creation_id: $creation_id) {
      creator_id
      creator_name
      creation_name
      frequency
      time_of_day
      colors
      fragrances
      ingredients
      extras
    }
  }
`;
