const AppConfig = {
  baseCloudinaryURL: 'https://res.cloudinary.com/lush/image/upload',
  languages: ['en'],
  languageTitles: {
    en: 'English',
  },
  languages_obj: [{ value: 'en', label: 'English' }],
  name: 'lush',
  twitter_sharing_image:
    'https://res.cloudinary.com/lush/image/upload/v1535031872/lush_com/site_assets/labs/twitter.png',
  facebook_sharing_image:
    'https://res.cloudinary.com/lush/image/upload/v1535031872/lush_com/site_assets/labs/facebook.png',
  image_path: 'https://res.cloudinary.com/lush/image/upload',
  hero_image_sizes: [
    {
      key: 'hero',
      label: 'H',
      width: 1400,
      height: 560,
    },
  ],
  content_image_sizes: [
    {
      key: 'small',
      label: 'sm',
      width: 700,
      height: 390,
    },
    {
      key: 'medium',
      label: 'md',
      width: 880,
      height: 490,
    },
    {
      key: 'large',
      label: 'lg',
      width: 1400,
      height: 520,
    },
    {
      key: 'portrait',
      label: 'p',
      width: 320,
      height: 480,
    },
  ],
  content_video_sizes: [
    {
      key: 'small',
      label: 'sm',
      width: 700,
      height: 394,
    },
    {
      key: 'medium',
      label: 'md',
      width: 880,
      height: 494,
    },
    {
      key: 'large',
      label: 'lg',
      width: 1400,
      height: 520,
    },
    {
      key: 'portrait',
      label: 'p',
      width: 320,
      height: 480,
    },
  ],
  image_block_default: {
    cdn: 'cloudinary',
    selected_type: '',
    selected_size: '',
    alt: '',
    edited: {
      name: '',
      directory: '',
      file_extension: '',
      link: '',
      height: '',
      width: '',
    },
    original: {
      name: '',
      directory: '',
      file_extension: '',
      link: '',
    },
    transformation: {
      scale: 1,
      position: {
        x: 0,
        y: 0,
      },
    },
  },
  ga: {
    tracking_id: 'UA-79597449-2',
    player_cookie_domain: 'player.lush.com',
  },
};

//
module.exports = AppConfig;
