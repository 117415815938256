import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import PropType from 'prop-types';
import { useLocation } from 'react-router-dom';

const withRouteBehaviour = (WrappedComponent) => {
  const WithRouteBehaviourWrapper = ({ title, ...props }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return (
      <>
        <Helmet>
          <title>{t(title)}</title>
        </Helmet>
        <WrappedComponent title={title} {...props} />
      </>
    );
  };

  WithRouteBehaviourWrapper.defaultProps = {
    title: '',
  };

  WithRouteBehaviourWrapper.propTypes = {
    title: PropType.string,
  };

  return WithRouteBehaviourWrapper;
};

export default withRouteBehaviour;
