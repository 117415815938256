import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { appEnvironmentRoutes, ENV } from '@lushdigital/helper-functions';

//
import PageHelmet from '../pageHelmet';
import Header from '../header';

//
export const ProjectContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex: 1 1 100%;
  padding-top: 80px;
`;

const PageWrapper = ({ title, route, children }) => {
  const { i18n } = useTranslation();

  return (
    <ProjectContainer>
      <PageHelmet
        loading={false}
        type="website"
        page={{ title }}
        url={`${appEnvironmentRoutes(ENV).CORE}${route.replace(':language', i18n.language)}`}
      />
      <Header />
      {children}
    </ProjectContainer>
  );
};

PageWrapper.propTypes = {
  route: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default PageWrapper;
