import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SrOnly from '@lushdigital/ui/srOnly';

const ColorItem = styled.div`
  margin: 0 4em 4em 0;

  &:nth-child(3n) {
    margin-right: 0;
  }

  &:nth-last-child(-n + 3) {
    margin-bottom: 0;
  }
`;
const CheckboxContainer = styled.div`
  width: 5em;
  height: 5em;
`;

const StyledCheckbox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.hex};
  margin: 0 auto;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: all 150ms;
  cursor: pointer;

  &:before {
    position: absolute;
    content: '';
    top: 7px;
    left: 7px;
    width: calc(100% - 14px);
    height: calc(100% - 14px);
    transition: all 150ms;
  }

  ${SrOnly}:checked + & {
    &:before {
      top: -7px;
      left: -7px;
      width: calc(100% + 14px);
      height: calc(100% + 14px);
      border-radius: 50%;
      box-shadow: 0px 0px 0px 3px #fff;
    }
  }
  ${SrOnly}:disabled + & {
    opacity: 0.7;
    cursor: initial;
  }
`;

const Color = ({ color, increment, decrement, selected, disabled }) => {
  const { t } = useTranslation();

  const updateSelected = () => {
    const currentState = !selected;
    if (currentState) {
      increment(color);
    } else {
      decrement(color);
    }
  };

  return (
    <ColorItem>
      <label htmlFor={color.tag_name}>
        <SrOnly>{t(`${color.label}`)}</SrOnly>
        <CheckboxContainer>
          <SrOnly
            as="input"
            type="checkbox"
            id={color.tag_name}
            checked={selected}
            onChange={updateSelected}
            disabled={disabled && !selected}
          />
          <StyledCheckbox hex={color.hex} />
        </CheckboxContainer>
      </label>
    </ColorItem>
  );
};

Color.propTypes = {
  color: PropTypes.shape({
    label: PropTypes.string.isRequired,
    tag_name: PropTypes.string.isRequired,
    hex: PropTypes.string.isRequired,
  }).isRequired,
  increment: PropTypes.func.isRequired,
  decrement: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default Color;
