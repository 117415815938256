import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import routes from '../../routes';
import PageWrapper from '../../components/pageWrapper';
import ViewCreationContent from './viewCreationContent';
import { CVContainer } from '../../helpers/helpers.styles';
import { CreationContent } from './viewCreation.styles';

const ViewCreation = ({ match }) => {
  const { t } = useTranslation();

  const creationId = match.params.creation_id;

  return (
    <PageWrapper title={t('CV_VIEW_CREATION')} route={routes.CREATION.path.replace(':creation_id', creationId)}>
      <CVContainer>
        <CreationContent>
          <ViewCreationContent creationId={creationId} />
        </CreationContent>
      </CVContainer>
    </PageWrapper>
  );
};

ViewCreation.propTypes = {
  match: PropTypes.shape({ params: PropTypes.object.isRequired }).isRequired,
};

export default ViewCreation;
