import has from 'lodash/has';
import AppConfig from '../config';
/**
 * Simple exmaple function to return a string
 * @param  {String} something Random string
 * @return {String}           Return the random string
 */
export const exampleHelper = (something) => something;

export function strToURL(url) {
  return url
    .toLowerCase()
    .trim()
    .replace(/\s|[-]+/g, '-')
    .replace(/\/|\.|<|>|'|'|\\|"|,|\?|'|=|&/g, ''); // replace known troublesome characters
}

export function sanitize(value) {
  return value.replace(/<[^>]+>/g, '');
}
export function generateSocialImage(hero, width, height) {
  return has(hero, 'edited.link') && hero.edited.link !== ''
    ? `${AppConfig.baseCloudinaryURL}/c_lfill,h_${height},w_${width},q_auto,f_auto,fl_lossy/${hero.edited.directory}/${hero.edited.name}.jpg`
    : AppConfig.twitter_sharing_image;
}
