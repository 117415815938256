import * as Sentry from '@sentry/browser';
import { BUILD_ENV } from '@lushdigital/helper-functions';

import { sentryKey } from '../config/config.sentry';

Sentry.init({
  dsn: sentryKey,
  environment: BUILD_ENV,
  // release: webpackVars.VERSION
});

export function logSentryException({ ex, res, body, allow404, allow401, allow422, extra = {}, user = {} }) {
  // If we aren't in the development environment check what the error code is
  const code = res ? Number(res.code) : 0;
  // Check if the response code is 404, 422 or 401 and flag to ignore exception unless allowed
  let sendException = true;
  if (code && (code === 404 || code === 401 || code === 422)) {
    sendException = (code === 401 && allow401) || (code === 404 && allow404) || (code === 422 && allow422);
  }
  // Only send exception if allowed code
  if (sendException) {
    Sentry.withScope((scope) => {
      scope.setTag('error function called', 'logSentryException');

      scope.setExtra('api_response', res);
      scope.setExtra('post_body', body);

      Object.keys(extra).forEach((v) => {
        scope.setExtra(v, extra[v]);
      });

      if (user.id) {
        scope.setUser({ username: 'AUTHENTICATED USER' });
      }
      Sentry.captureException(ex);
    });
    // eslint-disable-next-line
    window.console && console.error && console.error(ex);
  }
}

export function logApolloException({ name, code, error, allow401, allow404, allow422, extra = {}, user = {} }) {
  // Check if the response code is 404, 422 or 401 and flag to ignore exception unless allowed
  let sendException = true;
  if (code && (code === 404 || code === 401 || code === 422)) {
    sendException = (code === 401 && allow401) || (code === 404 && allow404) || (code === 422 && allow422);
  }

  // Only send exception if allowed code
  if (sendException) {
    Sentry.withScope((scope) => {
      scope.setTag('error function called', 'logApolloException');
      scope.setFingerprint([name, 'logApolloException']);

      if (error.graphQLErrors) {
        scope.setExtra('gql error', error.graphQLErrors);
      }

      scope.setExtra('name', name);

      Object.keys(extra).forEach((v) => {
        scope.setExtra(v, extra[v]);
      });

      if (user.id) {
        scope.setUser({ username: 'AUTHENTICATED USER' });
      }
      Sentry.captureException(error);
    });
    // eslint-disable-next-line
    console && console.error && console.error(error);
  }
}

export function __DEPRECATED__logSentryException(ex, res, post = null) { // eslint-disable-line
  logSentryException({
    ex,
    res,
    body: post,
  });
}
