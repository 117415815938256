import gql from 'graphql-tag';

export const ADD_CREATION = gql`
  mutation add_creation(
    $creator_id: Int!
    $creator_name: String!
    $creation_name: String!
    $frequency: [String]!
    $time_of_day: [String]!
    $colors: [String]!
    $fragrances: [String]!
    $ingredients: [String]
    $extras: [String]
  ) {
    add_creation(
      creator_id: $creator_id
      creator_name: $creator_name
      creation_name: $creation_name
      frequency: $frequency
      time_of_day: $time_of_day
      colors: $colors
      fragrances: $fragrances
      ingredients: $ingredients
      extras: $extras
    ) {
      id
      creation_data {
        creator_id
        creator_name
        creation_name
        frequency
        time_of_day
        colors
        fragrances
        ingredients
        extras
      }
    }
  }
`;
